/**
 * Add your config changes here.
 * @module config
 */
import bookSVG from '@plone/volto/icons/book.svg';

import RubricaView from '@package/components/Blocks/Rubrica/View.jsx';
import RubricaEdit from '@package/components/Blocks/Rubrica/Edit.jsx';
import FarmaciaView from '@package/components/View/FarmaciaView/FarmaciaView.jsx';
import farmacieSVG from '@plone/volto/icons/first-aids.svg';
import SearchFarmaciaView from '@package/components/Blocks/SearchFarmacia/View.jsx';
import SearchFarmaciaEdit from '@package/components/Blocks/SearchFarmacia/Edit.jsx';
import applyItaliaConfig from '@italia/config/italiaConfig';
import '@plone/volto/config';
import BlocksWidget from '@package/components/Widgets/BlocksWidget';
import PersonaAmbulatori from '@package/components/View/PersonaView/PersonaAmbulatori';

import {
  ContentImage,
  VenueDescription,
  VenueServices,
  VenueAccessMode,
  VenueWhere,
  VenuePublicTimetable,
  VenueContacts,
  VenueMoreInfos,
  UOWhatDoesItDo,
  UOContacts,
  UOPeople,
  UOStructure,
  UOServices,
  UODocuments,
  UOMoreInfos,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

export default function applyConfig(voltoConfig) {
  const config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,

    // defaults
    matomoUrlBase: 'https://piwik.auslromagna.it/matomo/',
    matomoSiteId: '3',
    // matomoSecondUrlBase
    // matomoSecondSiteId
    // TODO: riverificare queste impostazioni
    matomo: {
      ...(config.settings.matomo || {}),
      configurations: {
        ...(config.settings.matomo?.configurations || {}),
        setCookieDomain: '.auslromagna.it',
        setDomains: [
          '*.auslromagna.it',
          '*.amministrazionetrasparente.auslromagna.it',
          '*.www.auslromagna.it',
          '*.amministrazionetrasparente.auslromagna.it',
          '*.bancadellacute.auslromagna.it',
          '*.bollettino.auslromagna.it',
          '*.forms.auslromagna.it',
          '*.sondaggi.auslromagna.it',
        ],
        enableCrossDomainLinking: true,
        setDoNotTrack: true,
        disableCookies: true,
        enableLinkTracking: true,
      },
    },
    // appExtras: [
    //   ...config.settings.appExtras,
    //   {},
    // ],
    italiaThemeViewsConfig: {
      ...config.settings.italiaThemeViewsConfig,
      //imagePosition: 'afterHeader', // possible values: afterHeader, documentBody

      Venue: {
        ...(config.settings.italiaThemeViewsConfig['Venue'] ?? {}),
        sections: [
          {
            /* HEADER IMAGE */
            component: ContentImage,
            props: { position: 'documentBody' },
          },
          { /* DESCRIZIONE */ component: VenueDescription },
          { /* MAPPA */ component: VenueWhere },
          { /* CONTATTI */ component: VenueContacts },
          { /* ORARIO AL PUBBLICO */ component: VenuePublicTimetable },
          { /* MODALITA DI ACCESSO */ component: VenueAccessMode },
          { /* SERVIZI CORRELATI */ component: VenueServices },
          { /* ULTERIORI INFORMAZIONI */ component: VenueMoreInfos },
        ],
      },
      Persona: {
        ...config.settings.italiaThemeViewsConfig.Persona,
        // persona è sovrascritto anche da alp (libera professione),
        // qui si inserisce la sezione "AMBULATORI", prima dell'ultima sezione
        // (che è / dovrebbe essere "ULTERIORI INFORMAZIONI")
        sections: config.settings.italiaThemeViewsConfig.Persona.sections
          .slice(
            0,
            config.settings.italiaThemeViewsConfig.Persona.sections.length - 1,
          )
          .concat([{ /* AMBULATORI */ component: PersonaAmbulatori }])
          .concat(
            config.settings.italiaThemeViewsConfig.Persona.sections.slice(
              config.settings.italiaThemeViewsConfig.Persona.sections.length -
                1,
            ),
          ),

        // { /* RUOLO */ component: PersonaRuolo },
        // { /* CONTATTI */ component: PersonaContatti },
        // { /* LIBERA PROFESSIONE */ component: PersonaLiberaProfessione }, <-- aggiunto da auslfe-alp
        // { /* DOCUMENTI */ component: PersonaDocumenti },
        // { /* ULTERIORI INFORMAZIONI */ component: PersonaUlterioriInformazioni },
      },
      UnitaOrganizzativa: {
        ...(config.settings.italiaThemeViewsConfig['Venue'] ?? {}),
        sections: [
          {
            /* HEADER IMAGE */
            component: ContentImage,
            props: { position: 'documentBody' },
          },
          { /*** COSA FA ***/ component: UOWhatDoesItDo },
          { /*** CONTATTI ***/ component: UOContacts },
          { /*** STAFF ***/ component: UOPeople },
          { /*** STRUTTURA ***/ component: UOStructure },
          { /*** SERVIZI ***/ component: UOServices },
          { /* DOCUMENTI */ component: UODocuments },
          { /* ULTERIORI INFORMAZIONI */ component: UOMoreInfos },
        ],
      },
    },

    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'Servizio Sanitario Regionale Emilia-Romagna',
      siteSubtitle: 'Azienda Unità Sanitaria Locale della Romagna',
      parentSiteTitle: 'Regione Emilia-Romagna',
      parentSiteURL: 'https://www.regione.emilia-romagna.it/',
      subsiteParentSiteTitle: 'AUSL della Romagna',
      arLoginUrl: '/area-dipendenti',
      // arLogoutUrl: '/logout',
      markSpecialLinks: false,
      // smallFooterLinks: {
      //   default: [{ title: 'Mappa del sito', url: '/sitemap' }],
      //   it: [{ title: 'Mappa del sito', url: '/it/sitemap' }],
      //   en: [{ title: 'Sitemap', url: '/en/sitemap' }],
      // },
    },
    isMultilingual: false,
    supportedLanguages: ['it', 'en'],
    defaultLanguage: 'it',
  };

  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Farmacia: FarmaciaView,
  };

  config.widgets.widget = {
    ...config.widgets.widget,
    blocks: BlocksWidget,
  };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      // {
      //   id: 'cardWithSideImageTemplate',
      //   isDefault: false,
      //   title: 'Card con immagine affiancata',
      //   template: CardWithSideImageTemplate,
      //   skeleton: CardWithSideImageTemplateSkeleton,
      //   schemaEnhancer: ({ schema, formData, intl }) => {
      //     let pos = addDefaultOptions(schema, formData, intl);
      //     addCardWithSideImageTemplateOptions(schema, formData, intl, pos);
      //     return schema;
      //   },
      // },
    ],
    listing_bg_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
    listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  };

  config.blocks.blocksConfig.rubrica = {
    id: 'rubrica',
    title: 'Rubrica',
    icon: bookSVG,
    group: 'search',
    view: RubricaView,
    edit: RubricaEdit,
    restricted: false,
    mostUsed: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 0,
  };

  config.blocks.blocksConfig.searchFarmacia = {
    id: 'searchFarmacia',
    title: 'Ricerca farmacie', // ricerca turni o ferie farmacie
    icon: farmacieSVG,
    group: 'search',
    view: SearchFarmaciaView,
    edit: SearchFarmaciaEdit,
    restricted: false,
    mostUsed: false,
    security: {
      addPermission: [],
      view: [],
    },
    sidebarTab: 1,
  };

  config.settings['volto-blocks-widget'].allowedBlocks = [
    ...config.settings['volto-blocks-widget'].allowedBlocks,
    'repeatableContentBlock',
  ];

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2024-05-06T14:00:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
            },
          },
          // ...config.settings[
          //   'volto-gdpr-privacy'
          // ].defaultPanelConfig.technical.choices.filter(
          //   (f) => f.config_key !== 'GANALYTICS',
          // ),
        ],
      },
    },
  };

  return config;
}
